import { request } from '@/network/request'

export function addTeacherInfo(data) {
  return request({
    url: '/Teacher_management/addTeacher',
    method: 'post',
    data: data,
  })
}

export function updateTeacher(data) {
  return request({
    url: '/Teacher_management/updateTeacher',
    method: 'post',
    data: data,
  })
}


export function getAllTeacher(params) {
  return request({
    url: '/Teacher_management/getAllTeacher',
    method: 'get',
    params: params,
  })
}
export function searchTeacher(params) {
  return request({
    url: '/Teacher_management/searchTeacher',
    method: 'get',
    params: params,
  })
}
export function deleteTeacher(params) {
  return request({
    url: '/Teacher_management/deleteTeacher',
    method: 'get',
    params: params,
  })
}
