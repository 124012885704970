<template>
  <div>
    <div class="headerText">
      <span style="font-weight: bold; color: #777777">课程数据</span>
    </div>

    <div class="functionBar">
     <div class="btns">
        <el-button type="primary" @click="handleAdd()">新增</el-button>
         <el-button type="primary" @click="addcourse()">新增课程</el-button>
      <el-button plain @click="pushDialogVisible = true">推送设置</el-button>

     </div>
     <div class="select">
        <el-select
        v-model="valueOfYear"
        placeholder="入学年份"
        class="SignIn-selectYear"
        clearable
      >
        <el-option
          v-for="item in optionsOfYear"
          :key="item.valueOfYear"
          :label="item.label"
          :value="item.valueOfYear"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="valueOfClass"
        placeholder="班级"
        class="SignIn-selectClass"
        clearable
      >
        <el-option
          v-for="item in optionsOfClass"
          :key="item.valueOfClass"
          :label="item.label"
          :value="item.valueOfClass"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="searchInFo()">搜索</el-button>
     </div>
    </div>

    <div class="dataTable">
      <el-table :data="calTableData" border width="100%">
        <el-table-column prop="className" label="班级" align="center">
        </el-table-column>
        <el-table-column prop="classDate" label="入学年份" align="center">
        </el-table-column>
        <el-table-column prop="className" label="课程名称" align="center">
        </el-table-column>
        <el-table-column prop="teacherName" label="任课老师" align="center">
        </el-table-column>
         <el-table-column prop="courseSite" label="上课地点" align="center">
        </el-table-column>
        <el-table-column prop="courseTime" label="上课时间" align="center" >
        </el-table-column>
        <!-- <el-table-column prop="studentNum" label="班级人数" align="center">
        </el-table-column> -->
        <!-- <el-table-column prop="attendanceNum" label="出勤" align="center">
        </el-table-column>
        <el-table-column prop="absenteeismNum" label="缺勤" align="center">
        </el-table-column>
        <el-table-column prop="thingLeaveNum" label="事假" align="center">
        </el-table-column>
        <el-table-column prop="sickLeaveNum" label="病假" align="center">
        </el-table-column>
        <el-table-column prop="lateNum" label="迟到" align="center">
        </el-table-column>
        <el-table-column prop="earlyLeaveNum" label="早退" align="center">
        </el-table-column> -->
        <el-table-column prop="updateTime" label="更新时间" align="center">
        </el-table-column>
        <el-table-column prop="operation" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="tableTag">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
       <!-- 新增 -->
    <!-- <el-dialog
      title="新增课程"
      :visible.sync="addDialogVisible"
      :before-close="handleClose()"
      width="50%"
    >
      <el-form
        :model="addCourseName"
        label-width="100px"
      >
      
          <el-form-item label="课程名称" prop="courseName" >
          <el-input
            placeholder="课程名称"
            v-model="addCourseName.courseName"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
       
       
  
      </el-form>
     
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            addDialogVisible = false;
            handPut();
          "
          >保 存</el-button
        >
        <el-button @click="addDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog> -->
    <!-- 新增 -->
    <el-dialog
      title="新增签到"
      :visible.sync="newDialogVisible"
      :before-close="handleClose()"
      width="50%"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="班级" prop="className">
          <el-select
            v-model="addForm.className"
            placeholder="请选择"
            @change="selectClass()"
          >
            <el-option
              v-for="item in optionsOfClass"
              :key="item.valueOfClass"
              :label="item.label"
              :value="item.valueOfClass"
            >
            </el-option>
          </el-select>
        </el-form-item>
            <el-form-item label="课程名称" prop="courseName">
          <el-select v-model="addForm.courseName" placeholder="选择课程名称">
            <el-option
              v-for="item in optionsOfcurriculum"
              :key="item.valueOfClassName"
              :label="item.label"
              :value="item.valueOfClassName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上课时间" required>
          <el-col :span="11">
            <el-form-item prop="courseTime">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="addForm.courseTime"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
          <el-form-item label="上课地点" prop="courseSite" >
          <el-input
            placeholder="上课地点"
            v-model="addForm.courseSite"
            style="width: 20%"
          >
          </el-input>
        </el-form-item>
    
        <el-form-item label="任课老师" prop="teacherName">
          <el-input
            placeholder="任课老师"
            v-model="addForm.teacherName"
            style="width: 20%"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="签到情况" prop="signInSituation">
          <div class="signInTable">
            <el-table :data="SignInTableData" border style="width: 100%">
            
              <el-table-column prop="stuId" label="学号" align="center">
              </el-table-column>
              <el-table-column prop="stuName" label="姓名" align="center">
              </el-table-column>
                 <el-table-column prop="classHours" label="学时" align="center">
                      <template slot-scope="scope">
              <el-input v-model="scope.row.classHours" clearable style="width: 110px" />
            </template>
              </el-table-column>
              <el-table-column prop="credit" label="学分" align="center">
                   <template slot-scope="scope">
              <el-input v-model="scope.row.credit" clearable style="width: 110px" />
            </template>
              </el-table-column>
              <!-- <el-table-column label="签到情况" align="center">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.signIN" placeholder="请选择">
                    <el-option
                      v-for="item in optionsOfSignIn"
                      :key="item.valueOfSignIn"
                      :label="item.label"
                      :value="item.valueOfSignIn"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            newDialogVisible = false;
            handlePut();
          "
          >保 存</el-button
        >
        <el-button @click="newDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑签到"
      :visible.sync="editDialogVisible"
      :before-close="handleClose()"
      width="50%"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="班级" prop="className">
          <el-select
            v-model="editForm.className"
            placeholder="请选择"
            @change="selectClassd()"
          >
            <el-option
              v-for="item in optionsOfClass"
              :key="item.valueOfClass"
              :label="item.label"
              :value="item.valueOfClass"
            >
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="课程名称" prop="courseName">
          <el-select v-model="editForm.courseName" placeholder="选择课程名称">
            <el-option
              v-for="item in optionsOfcurriculum"
              :key="item.valueOfClassName"
              :label="item.label"
              :value="item.valueOfClassName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上课时间" >
          <el-col :span="11">
            <el-form-item prop="courseTime">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="editForm.courseTime"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
         
          <el-form-item label="上课地点" prop="courseSite" >
          <el-input
            placeholder="上课地点"
            v-model="editForm.courseSite"
            style="width: 20%"
          >
          </el-input>
        </el-form-item>
       
        <el-form-item label="任课老师" prop="teacherName">
          <el-input
            placeholder="任课老师"
            v-model="editForm.teacherName"
            style="width: 20%"
            :disabled="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="签到情况" prop="signInSituation">
          <div class="signInTable">
            <el-table :data="SignInTableData" border style="width: 100%">
              <el-table-column prop="stuId" label="学号" align="center">
              </el-table-column>
              <el-table-column prop="stuName" label="姓名" align="center">
              </el-table-column>
                <el-table-column prop="classHours" label="学时" align="center">
                    <template slot-scope="scope">
              <el-input v-model="scope.row.classHours" clearable style="width: 110px" />
            </template>
              </el-table-column>
              <el-table-column prop="credit" label="学分" align="center">
                 <template slot-scope="scope">
              <el-input v-model="scope.row.credit" clearable style="width: 110px" />
            </template>
              </el-table-column>
              <!-- <el-table-column
                prop="sighInSituation"
                label="签到情况"
                align="center"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.valueOfSignIn"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in optionsOfSignIn"
                      :key="item.valueOfSignIn"
                      :label="item.label"
                      :value="item.valueOfSignIn"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            editDialogVisible = false;
            handleStore();
          "
          >保 存</el-button
        >
        <el-button @click="editDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 推送设置 -->
    <el-dialog
      title="推送设置"
      :visible.sync="pushDialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="班级出勤率推送" name="first">
          <span style="margin-left:4.5%"
            >是否推送
            <el-switch
              v-model="isPushProbability"
              active-color="#13ce66"
              inactive-color="#CCCCCC"
              style="margin-left:2%"
            >
            </el-switch>
          </span>
          <el-form
            :model="pushProbabilityForm"
            rules="pushProbabilityFormRules"
            ref="pushProbabilityFormRef"
            label-width="100px"
            v-show="isPushProbability == true"
            style="margin-top:2%"
          >
            <el-form-item>
              当出勤率小于等于
              <el-input
                v-model="inputProbability"
                style="width:80px;margin:1%"
              ></el-input
              >%
            </el-form-item>
            <el-form-item>
              通知
              <el-checkbox v-model="checkedClassTeacher" style="margin-left:5%"
                >班主任</el-checkbox
              >
              <el-checkbox v-model="checkedTeacherName">任课老师</el-checkbox>
            </el-form-item>
            <el-form-item prop="noticeContent">
              通知内容
              <el-input
                v-model="pushProbabilityForm.noticeContent"
                style="width: 40%；margin-left:3%"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="学生出勤次数推送" name="second">
          <span style="margin-left:4.5%"
            >是否推送
            <el-switch
              v-model="isPushNumber"
              active-color="#13ce66"
              inactive-color="#CCCCCC"
              style="margin-left:2%"
            >
            </el-switch>
          </span>
          <el-form
            :model="pushNumberForm"
            rules="pushNumberFormRules"
            ref="pushNumberFormRef"
            label-width="100px"
            v-show="isPushNumber == true"
            style="margin-top:2%"
          >
            <el-form-item>
              一个学生连续<el-input
                v-model="inputNumber"
                style="width:80px;margin:1%"
              ></el-input
              >次未出勤
            </el-form-item>
            <el-form-item>
              通知
              <el-checkbox v-model="checkedClassTeacher" style="margin-left:5%"
                >班主任</el-checkbox
              >
              <el-checkbox v-model="checkedTeacherName">任课老师</el-checkbox>
            </el-form-item>
            <el-form-item prop="noticeContent">
              通知内容
              <el-input
                v-model="pushNumberForm.noticeContent"
                style="width: 40%；margin-left:3%"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushDialogVisible = false"
          >保 存</el-button
        >
        <el-button @click="pushDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "SignIn",
  data() {
    return {
      pushNumberForm: {
        noticeContent: "",
      },
      studentNum:0,
      pushProbabilityForm: {
        noticeContent: "",
      },
      checkedClassTeacher: true,
      checkedTeacherName: false,
      activeName: "first",
      isPushProbability: false,
      isPushNumber: false,
      pushDialogVisible: false,
      courdeSite:'',
      classHours:'0',
      credit:0,
      optionsOfSignIn: [
        {
          valueOfSignIn: "出勤",
          label: "出勤",
        },
        {
          valueOfSignIn: "缺勤",
          label: "缺勤",
        },
        {
          valueOfSignIn: "事假",
          label: "事假",
        },
        {
          valueOfSignIn: "病假",
          label: "病假",
        },
        {
          valueOfSignIn: "迟到",
          label: "迟到",
        },
        {
          valueOfSignIn: "早退",
          label: "早退",
        },
      ],
      valueOfSignIn: "",
      SignInTableData: [],
      newDialogVisible: false,
      editDialogVisible: false,
      addForm: {},
      addCourseName:{},
      addFormRules: {
        className: [
          {
            required: true,
            message: "请选择班级",
            trigger: "blur",
          },
        ],
        courseTime: [
          {
            type: "date",
            required: true,
            message: "请选择上课时间",
            trigger: "blur",
          },
        ],
        courseName: [
          {
            required: true,
            message: "请选择课程名称",
            trigger: "blur",
          },
          
        ],
      },
      editForm: {},
      editFormRules: {},
      optionsOfYear: [
        {
          valueOfYear: "2021",
          label: "2021",
        },
        {
          valueOfYear: "2020",
          label: "2020",
        },
      ],
      valueOfYear: "",
      optionsOfClass: [
      
      ],
      valueOfClass: "",
      currentPage: 1,
      pagesize: 5,
      tableData: [],
      inputNumber: "",
      earlyLeaveNum:0,
      inputProbability: "",
      inputOfTeacher: "",
      optionsOfcurriculum: [
      
      ],
      valueOfClassName: "",
    };
  },
  created() {
    this.tablePrint();
    this.printClassOption();
    this.getcourseName();
  },
  mounted() {},
  methods: {
    addcourse(){
     this.$router.push('Course');
    },
    handPut(){
       let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/getName",
        data: {
         
          courseName: that.addCourseName.courseName,
      
        },
      })
        .then((response) => {
          
          console.log(response)
    
          that.tablePrint();
        })
        .catch((error) => {
          console.log(error);
        });
    }
,async getcourseName(){
   let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/courseName",
      })
        .then((response) => {
          console.log(response);
          let i = 0;
          while (response.data[i]) {
            that.optionsOfcurriculum.push(JSON.parse(response.data[i++]));
          }
          console.log(that.optionsOfcurriculum);
        })
        .catch((error) => {
          console.log(error);
        });
},
  async  selectClass() {
      let that = this;
      that.SignInTableData = [];
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/selectClass",
        data: {
          className: that.addForm.className,
        },
      })
        .then((response) => {
          let i = 0;
          while (response.data[i]) {
            that.SignInTableData.push(response.data[i++]);
          }
           that.tablePrint();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async  selectClassd() {
      let that = this;
      that.SignInTableData = [];
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/selectClass",
        data: {
          stuClass: that.editForm.className,
        },
      })
        .then((response) => {
          let i = 0;
          while (response.data[i]) {
            that.SignInTableData.push(response.data[i++]);
          }
           that.tablePrint();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    storeNew() {},
    handleClose() {
      // this.$confirm('确认关闭？')
      //     .then(() => {
      //       this.newDialogVisible = false;
      //       this.editDialogVisible = false;
      //     })
      //     .catch((e)=> {
      //       console.log(e);
      //     });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //    async handleAdd() {
    //   this.$router.push({ path: 'class/edit' })
    // },
    handleAdd() {
      this.newDialogVisible = true;
      this.addForm = {};
      this.SignInTableData = [];
    },
    handlePut() {
      this.upstu();
      let that = this;
      var Time = new Date();
      var updatetime = Time.toLocaleString();
      let index = that.attendanceFrom.length + 1;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/Class_attendance/new/newAtForm",
        data: {
          attendanceFormNo: index,
          className: that.addForm.className,
          courseTime: that.addForm.courseTime,
          courseName: that.addForm.courseName,
          courseSite:that.addForm.courseSite,
          teacherName: that.addForm.teacherName,
          attendanceFrom: that.addForm.attendanceFrom,
          updateTime: updatetime,
        },
      })
        .then((response) => {
          console.log(response);
          that.tableData=response.data;
           that.SignInTableData = response.data.attendanceFrom;
          that.tablePrint();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    upstu(){
 let that = this;
  
 
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/upstu",
        data: this.SignInTableData,
      })
        .then((response) => {
          console.log(response);
      
          that.tablePrint();
        })
        .catch((error) => {
          console.log(error);
        });


    },
      printClassOption() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/Classlist",
      })
        .then((response) => {
          console.log(response);
          let i = 0;
          while (response.data[i]) {
            that.optionsOfClass.push(JSON.parse(response.data[i++]));
          }
          console.log(that.optionsOfClass);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 搜索
    searchInFo() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/Class_attendance/list",
        params: {
          classDate: that.valueOfYear,
          className: that.valueOfClass,
        },
      })
        .then((response) => {
          that.tableData = response.data;
          console.log(response);
          this.ablePrint();

        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 编辑按钮
    handleEdit(row) {
      this.editDialogVisible = true;
      this.editForm = row;
      console.log(this.editForm);
    },
    // 保存编辑 时间有问题
    handleStore() {
      let that = this;
      var Time = new Date();
      var updatetime = Time.toLocaleString();
    
 this.upstu();
    
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/Class_attendance/update",
        data: {
          attendanceFormNo: that.editForm.attendanceFormNo,
          className: that.editForm.className,
          courseName: that.editForm.courseName,
          teacherName: that.editForm.teacherName,
          courseSite:that.editForm.courseSite,
         courseTime:that.editForm.courseTime,
           attendanceFrom: that.editForm.attendanceFrom,
          // attendanceFrom: that.addForm.attendanceFrom,
          updateTime: updatetime,
        },
      })
        .then((response) => {
          console.log(response);
          // that.tableData = response.data;
          that.tablePrint();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 表格翻页
    handleSizeChange: function(size) {
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
    },
    // 表格渲染
    // that 是vue中的this这里需要保存一下 我也不知道为啥
    tablePrint() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/Class_attendance/list",
      })
        .then((response) => {
          console.log(response);
          that.tableData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    calTableData: function() {
      return this.tableData.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
    },
  },
};
</script>

<style>
.SignIn-functionBar {
  box-sizing: border-box;
  padding: 15px 0 0 0;
  display: flex;
}
.btns{
 display: flex;
  justify-content:flex-start ;
}
.select{
  display: flex;
  justify-content: flex-end ;
 margin-right: 8px;
}
.SignIn-selectYear {
  width: 150px;
margin-right: 8px;
}

.SignIn-selectClass {
  width: 150px;
 margin-right: 8px;
}

.SignIn-dataTable {
  box-sizing: border-box;
  padding: 15px 0 0 0;
}

.SignIn-tableTag {
  box-sizing: border-box;
  padding: 15px 0 0 0;
}
</style>
