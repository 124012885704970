<template>
<div id="app">

    <router-view></router-view>
    
</div>
</template>

<script>
export default {
    
    data() {
        return {
        };
    },
};
</script>

<style scoped>
#app {
    display: flex;
    width: 100vw;
    height: 100vh;
}


</style>
