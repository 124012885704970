<template>
  <div>
    <div class="headerText">
      <span style="font-weight: bold; color: #777777">入学基础数据</span>
    </div>

    <div class="functionBar">
      <div class="btns">
        <el-upload
          style="margin-left: 8px"
          class="upload-demo"
          action=""
          :on-change="handleChange"
          :show-file-list="false"
          :on-remove="handleRemove"
          :file-list="fileListUpload"
          :limit="limitUpload"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :auto-upload="false"
        >
          <el-button type="primary" style="margin-right: 10px">导入</el-button>
        </el-upload>
        <el-button
          type="primary"
          @click="
            addDialogVisible = true;
            newInfo();
          "
          >新增</el-button
        >
        <el-button type="primary" @click="exportExcel()">导出</el-button>
        <el-button
          type="danger"
          class="BasicData-deleteButton"
          @click="deleteInfo()"
          >删除</el-button
        >
      </div>

      <div class="select">
        <el-select
          class="BasicData-selectYear"
          v-model="valueOfYear"
          placeholder="入学年份"
          style="width: 15%"
          clearable
        >
          <el-option
            v-for="item in optionsOfYear"
            :key="item.valueOfYear"
            :label="item.label"
            :value="item.valueOfYear"
          >
          </el-option>
        </el-select>
        <el-select
          class="BasicData-selectClass"
          v-model="valueOfClass"
          placeholder="班级"
          style="width: 10%"
          clearable
        >
          <el-option
            v-for="item in optionsOfClass"
            :key="item.valueOfClass"
            :label="item.label"
            :value="item.valueOfClass"
          >
          </el-option>
        </el-select>
        <el-input
          class="BasicData-inputID"
          v-model="inputID"
          placeholder="学号"
          style="width: 10%"
          clearable
        ></el-input>
        <el-input
          class="BasicData-inputName"
          v-model="inputName"
          placeholder="姓名"
          style="width: 10%"
          clearable
        ></el-input>
        <el-button type="primary" @click="searchInFo()">搜索</el-button>
      </div>
    </div>

    <div class="dataTable">
      <el-table
        id="Student_table"
        :data="calTableData"
        border
        style="width: 100%"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column prop="stuId" label="学号" align="center" width="130px">
        </el-table-column>
        <el-table-column
          prop="stuName"
          label="姓名"
          align="center"
          width="110px"
        >
        </el-table-column>
        <el-table-column
          prop="schoolDate"
          label="入学年份"
          align="center"
          width="110px"
        >
        </el-table-column>
        <el-table-column prop="stuClass" label="班级" align="center">
        </el-table-column>
        <el-table-column
          prop="classTeacher"
          label="班主任"
          align="center"
          width="110px"
        >
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号码" align="center">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center">
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
          align="center"
          width="150px"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                editDialogVisible = true;
                editInFo(scope.row, false);
              "
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="
                editDialogVisible = true;
                editInFo(scope.row, true);
              "
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="tableTag">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[7, 14, 21, 28]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>

    <!-- dialog-新增 -->
    <!-- 内容主体区域 -->
    <el-dialog
      title="入学基础数据"
      :visible.sync="addDialogVisible"
      width="50%"
    >
      <el-form
        :inline="true"
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="150px"
      >
        <h3 style="margin-bottom: 12px">学生信息：</h3>
        <el-form-item label="学号" prop="stuId">
          <el-input
            v-model="addForm.stuId"
            label="请输入学号"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="姓名" prop="stuName">
          <el-input
            v-model="addForm.stuName"
            label="请输入姓名"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <div style="width: 94%">
            <el-select v-model="addForm.sex" placeholder="选择性别">
              <el-option
                v-for="item in optionsOfGender"
                :key="item.valueOfGender"
                :label="item.label"
                :value="item.valueOfGender"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="入学年份" prop="schoolDate">
          <div style="width: 94%">
            <el-select v-model="addForm.schoolDate" placeholder="选择年份">
              <el-option
                v-for="item in optionsOfYear"
                :key="item.valueOfYear"
                :label="item.label"
                :value="item.valueOfYear"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="身份证号码" prop="idCard">
          <el-input
            v-model="addForm.idCard"
            label="请输入身份证号码"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="班级" prop="stuClass">
          <el-select v-model="addForm.stuClass" placeholder="选择班级">
            <el-option
              v-for="item in optionsOfClass"
              :key="item.valueOfClass"
              :label="item.label"
              :value="item.valueOfClass"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否班委" prop="isCommittee">
          <el-select v-model="addForm.isCommittee" placeholder="请选择">
            <el-option
              v-for="item in optionsOfCommittee"
              :key="item.isCommittee"
              :label="item.label"
              :value="item.isCommittee"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否班助" prop="isAssistant">
          <el-select v-model="addForm.isAssistant" placeholder="请选择">
            <el-option
              v-for="item in optionsOfisAssistant"
              :key="item.isAssistant"
              :label="item.label"
              :value="item.isAssistant"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="出生日期" prop="birthDate">
          <el-input
            v-model="addForm.birthDate"
            label="请输入出生日期"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="班主任" prop="classTeacher">
          <el-select v-model="addForm.classTeacher" placeholder="选择班主任">
            <el-option
              v-for="item in optionsOfClassTeacher"
              :key="item.valueOfTeacher"
              :label="item.label"
              :value="item.valueOfTeacher"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="生源地" prop="originPlace">
          <el-input
            v-model="addForm.originPlace"
            label="请输入生源地"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="入学成绩" prop="adScore">
          <el-input
            v-model="addForm.adScore"
            label="请输入入学成绩"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="宗教信仰" prop="faith">
          <el-input
            v-model="addForm.faith"
            label="请输入宗教信仰"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="家庭住址" prop="homeLocating">
          <el-input
            v-model="addForm.homeLocating"
            label="请输入家庭住址"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phoneOfStudent">
          <el-input
            v-model="addForm.phoneOfStudent"
            label="请输入联系电话"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信号" prop="wechatOfStudent">
          <el-input
            v-model="addForm.wechatOfStudent"
            label="请输入微信号"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="QQ号" prop="QQNumber">
          <el-input
            v-model="addForm.QQNumber"
            label="请输入QQ号"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="emailOfStudent">
          <el-input
            v-model="addForm.emailOfStudent"
            label="请输入邮箱"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="选课课程" prop="myScourse">
          <el-input
            v-model="addForm.myScourse"
            label="请选课课程"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="心理健康情况" prop="mentalHealth">
          <el-input
            v-model="addForm.mentalHealth"
            label="请输入心理健康情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="文体活动参加情况" prop="recreation">
          <el-input
            v-model="addForm.recreation"
            label="请输入文体活动参加情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="社会实践参加情况" prop="socialPractice">
          <el-input
            v-model="addForm.socialPractice"
            label="请输入社会实践参加情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="科创参加情况" prop="innovation">
          <el-input
            v-model="addForm.innovation"
            label="请输入科创参加情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="作业完成情况" prop="homeWork">
          <el-input
            v-model="addForm.homeWork"
            label="请输入作业完成情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="上课出勤情况" prop="attendance">
          <el-input
            v-model="addForm.attendance"
            label="请输入上课出勤情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="考试得分" prop="testScorce">
          <el-input
            v-model="addForm.testScorce"
            label="请输入考试得分"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="学习信念" prop="studyBelief">
          <el-input
            v-model="addForm.studyBelief"
            label="请输入学习信念"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="学习目标" prop="studyTarget">
          <el-input
            v-model="addForm.studyTarget"
            label="请输入学习目标"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="学习投入" prop="studyInput">
          <el-input
            v-model="addForm.studyInput"
            label="请输入学习投入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="学习收获" prop="studyHarvest">
          <el-input
            v-model="addForm.studyHarvest"
            label="请输入学习收获"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="就业力" prop="employability">
          <el-input
            v-model="addForm.employability"
            label="请输入就业力"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <div style="margin-bottom: 12px">家长信息：</div>
        <el-form-item label="家长姓名" prop="nameOfParents">
          <el-input
            v-model="addForm.nameOfParents"
            label="请输入家长姓名"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="家长手机号" prop="phoneOfParent">
          <el-input
            v-model="addForm.phoneOfParent"
            label="请输入家长手机号"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="emailOfParent">
          <el-input
            v-model="addForm.emailOfParent"
            label="请输入邮箱"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="教育程度" prop="eduBackgroundOfParent">
          <el-input
            v-model="addForm.eduBackgroundOfParent"
            label="请输入教育程度"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="职业" prop="occupationOfParent">
          <el-input
            v-model="addForm.occupationOfParent"
            label="请输入职业"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="微信号" prop="wechatOfParent">
          <el-input
            v-model="addForm.wechatOfParent"
            label="请输入微信号"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="宗教信仰" prop="faithOfParent">
          <el-input
            v-model="addForm.faithOfParent"
            label="请输入宗教信仰"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="思想观念" prop="ideaOfParent">
          <el-input
            v-model="addForm.ideaOfParent"
            label="请输入思想观念"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="教育方式" prop="eduModeOfParent">
          <el-input
            v-model="addForm.eduModeOfParent"
            label="请输入教育方式"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="社会环境" prop="societyOfParent">
          <el-input
            v-model="addForm.societyOfParent"
            label="请输入社会环境"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="生活方式" prop="lifeStyleOfParent">
          <el-input
            v-model="addForm.lifeStyleOfParent"
            label="请输入生活方式"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            addDialogVisible = false;
            putInfo();
          "
          >保 存</el-button
        >
        <el-button @click="addDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- dialog-编辑 -->
    <el-dialog
      title="入学基础数据"
      :visible.sync="editDialogVisible"
      width="50%"
    >
      <el-form
        :inline="true"
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="150px"
        :disabled="view"
      >
        <h3 style="margin-bottom: 12px">学生信息：</h3>
        <el-form-item label="学号" prop="stuId">
          <el-input
            v-model="editForm.stuId"
            label="请输入学号"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="姓名" prop="stuName">
          <el-input
            v-model="editForm.stuName"
            label="请输入姓名"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <div style="width: 94%">
            <el-select v-model="editForm.sex" placeholder="选择性别">
              <el-option
                v-for="item in optionsOfGender"
                :key="item.valueOfGender"
                :label="item.label"
                :value="item.valueOfGender"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="入学年份" prop="schoolDate">
          <div style="width: 94%">
            <el-select v-model="editForm.schoolDate" placeholder="选择年份">
              <el-option
                v-for="item in optionsOfYear"
                :key="item.valueOfYear"
                :label="item.label"
                :value="item.valueOfYear"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="身份证号码" prop="idCard">
          <el-input
            v-model="editForm.idCard"
            label="请输入身份证号码"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="班级" prop="stuClass">
          <el-select v-model="editForm.stuClass" placeholder="选择班级">
            <el-option
              v-for="item in optionsOfClass"
              :key="item.valueOfClass"
              :label="item.label"
              :value="item.valueOfClass"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否班委" prop="isCommittee">
          <el-select v-model="editForm.isCommittee" placeholder="请选择">
            <el-option
              v-for="item in optionsOfCommittee"
              :key="item.isCommittee"
              :label="item.label"
              :value="item.isCommittee"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否班助" prop="isAssistant">
          <el-select v-model="editForm.isAssistant" placeholder="请选择">
            <el-option
              v-for="item in optionsOfisAssistant"
              :key="item.isAssistant"
              :label="item.label"
              :value="item.isAssistant"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="出生日期" prop="birthDate">
          <el-input
            v-model="editForm.birthDate"
            label="请输入出生日期"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="班主任" prop="classTeacher">
          <el-select v-model="editForm.classTeacher" placeholder="选择班主任">
            <el-option
              v-for="item in optionsOfClassTeacher"
              :key="item.valueOfTeacher"
              :label="item.label"
              :value="item.valueOfTeacher"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="生源地" prop="originPlace">
          <el-input
            v-model="editForm.originPlace"
            label="请输入生源地"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="入学成绩" prop="adScore">
          <el-input
            v-model="editForm.adScore"
            label="请输入入学成绩"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="宗教信仰" prop="faith">
          <el-input
            v-model="editForm.faith"
            label="请输入宗教信仰"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="家庭住址" prop="homeLocating">
          <el-input
            v-model="editForm.homeLocating"
            label="请输入家庭住址"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phoneOfStudent">
          <el-input
            v-model="editForm.phoneOfStudent"
            label="请输入联系电话"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信号" prop="wechatOfStudent">
          <el-input
            v-model="editForm.wechatOfStudent"
            label="请输入微信号"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="QQ号" prop="QQNumber">
          <el-input
            v-model="editForm.QQNumber"
            label="请输入QQ号"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="emailOfStudent">
          <el-input
            v-model="editForm.emailOfStudent"
            label="请输入邮箱"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="选课课程" prop="myScourse">
          <el-input
            v-model="editForm.myScourse"
            label="请选课课程"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="心理健康情况" prop="mentalHealth">
          <el-input
            v-model="editForm.mentalHealth"
            label="请输入心理健康情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="文体活动参加情况" prop="recreation">
          <el-input
            v-model="editForm.recreation"
            label="请输入文体活动参加情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="社会实践参加情况" prop="socialPractice">
          <el-input
            v-model="editForm.socialPractice"
            label="请输入社会实践参加情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="科创参加情况" prop="innovation">
          <el-input
            v-model="editForm.innovation"
            label="请输入科创参加情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="作业完成情况" prop="homeWork">
          <el-input
            v-model="editForm.homeWork"
            label="请输入作业完成情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="上课出勤情况" prop="attendance">
          <el-input
            v-model="editForm.attendance"
            label="请输入上课出勤情况"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="考试得分" prop="testScorce">
          <el-input
            v-model="editForm.testScorce"
            label="请输入考试得分"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="学习信念" prop="studyBelief">
          <el-input
            v-model="editForm.studyBelief"
            label="请输入学习信念"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="学习目标" prop="studyTarget">
          <el-input
            v-model="editForm.studyTarget"
            label="请输入学习目标"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="学习投入" prop="studyInput">
          <el-input
            v-model="editForm.studyInput"
            label="请输入学习投入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="学习收获" prop="studyHarvest">
          <el-input
            v-model="editForm.studyHarvest"
            label="请输入学习收获"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="就业力" prop="employability">
          <el-input
            v-model="editForm.employability"
            label="请输入就业力"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <div style="margin-bottom: 12px">家长信息：</div>
        <el-form-item label="家长姓名" prop="nameOfParents">
          <el-input
            v-model="editForm.nameOfParents"
            label="请输入家长姓名"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="家长手机号" prop="phoneOfParent">
          <el-input
            v-model="editForm.phoneOfParent"
            label="请输入家长手机号"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="emailOfParent">
          <el-input
            v-model="editForm.emailOfParent"
            label="请输入邮箱"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="教育程度" prop="eduBackgroundOfParent">
          <el-input
            v-model="editForm.eduBackgroundOfParent"
            label="请输入教育程度"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="职业" prop="occupationOfParent">
          <el-input
            v-model="editForm.occupationOfParent"
            label="请输入职业"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="微信号" prop="wechatOfParent">
          <el-input
            v-model="editForm.wechatOfParent"
            label="请输入微信号"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="宗教信仰" prop="faithOfParent">
          <el-input
            v-model="editForm.faithOfParent"
            label="请输入宗教信仰"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="思想观念" prop="ideaOfParent">
          <el-input
            v-model="editForm.ideaOfParent"
            label="请输入思想观念"
            style="width: 100%"
          ></el-input>
        </el-form-item>

        <el-form-item label="教育方式" prop="eduModeOfParent">
          <el-input
            v-model="editForm.eduModeOfParent"
            label="请输入教育方式"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="社会环境" prop="societyOfParent">
          <el-input
            v-model="editForm.societyOfParent"
            label="请输入社会环境"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="生活方式" prop="lifeStyleOfParent">
          <el-input
            v-model="editForm.lifeStyleOfParent"
            label="请输入生活方式"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          v-if="!view"
          @click="
            editDialogVisible = false;
            storeInfo();
          "
          >保 存</el-button
        >
        <el-button @click="editDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 导入弹窗 -->
    <el-dialog
      title="导入学生数据"
      :visible.sync="importdialogVisible"
      width="60%"
      center
    >
      <el-table :data="accountList" border style="width: 100%">
        <el-table-column
          prop="stuId"
          header-align="center"
          align="center"
          label="学号"
        >
        </el-table-column>
        <el-table-column
          prop="stuName"
          header-align="center"
          align="center"
          label="姓名"
        >
        </el-table-column>
        <el-table-column
          prop="schoolDate"
          header-align="center"
          align="center"
          label="年份"
        >
        </el-table-column>
        <el-table-column
          prop="stuClass"
          header-align="center"
          align="center"
          label="班级"
        >
        </el-table-column>
        <el-table-column
          prop="classTeacher"
          header-align="center"
          align="center"
          label="班主任"
        >
        </el-table-column>
        <el-table-column
          prop="idCard"
          header-align="center"
          align="center"
          label="身份证号码"
        >
        </el-table-column>
        <el-table-column
          prop="updatetime"
          header-align="center"
          align="center"
          label="更新时间"
        >
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="importExcel()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";

export default {
  name: "BasicData",
  data() {
    return {
      fileListUpload: [],
      limitUpload: 3,
      disabled: false,
      accountList: [],
      importdialogVisible: false,
      addDialogVisible: false,
      editDialogVisible: false,
      addForm: {},
      editForm: {},
      optionsOfYear: [],
      valueOfYear: "",
      optionsOfClassTeacher: [],
      valueOfTeacher: "",

      optionsOfGender: [
        {
          valueOfGender: "男",
          label: "男",
        },
        {
          valueOfGender: "女",
          label: "女",
        },
      ],
      optionsOfisAssistant: [
        {
          isAssistant: '1',
          label: "是",
        },
        {
          isAssistant: '0',
          label: "否",
        },
      ],

      optionsOfCommittee: [
        {
          isCommittee: '1',
          label: "是",
        },
        {
          isCommittee: '0',
          label: "否",
        },
      ],
      valueOfGender: "",
      optionsOfClass: [],
      valueOfClass: "",

      inputID: "",
      inputName: "",
      isCommittee: '',
      isAssistant: '',
      originPlace: "",
      adScore: "",
      faith: "",
      wechatOfStudent: "",
      emailOfStudent: "",
      myScourse: "",
      studyBelief: "",
      studyHarvest: "",
      studyTarget: "",
      testScorce: "",
      studyInput: "",
      employability: "",
      mentalHealth: "",
      recreation: "",
      socialPractice: "",
      innovation: "",
      attendance: "",
      homeWork: "",
      nameOfParents: "",
      phoneOfParent: "",
      emailOfParent: "",
      eduBackgroundOfParent: "",
      occupationOfParent: "",
      wechatOfParent: "",
      faithOfParent: "",
      ideaOfParent: "",
      eduModeOfParent: "",
      societyOfParent: "",
      lifeStyleOfParent: "",
      currentPage: 1,
      pagesize: 7,
      tableData: [],
      multipleSelection: [],
      addFormRules: {
        stuId: [
          {
            required: true,
            message: "请输入学号",
            trigger: "blur",
          },
        ],
        stuName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        // stuClass: [
        //   {
        //     required: true,
        //     message: "请选择班级",
        //     trigger: "blur",
        //   },
        // ],
        classTeacher: [
          {
            required: true,
            message: "请选择班主任",
            trigger: "blur",
          },
        ],
        idCard: [
          {
            required: true,
            message: "请输入身份证号码",
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "blur",
          },
        ],
        birthDate: [
          {
            required: true,
            message: "请输入出生日期",
            trigger: "blur",
          },
        ],
        homeLocating: [
          {
            required: true,
            message: "请输入家庭住址",
            trigger: "blur",
          },
        ],
        phoneOfStudent: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
        ],
        // nameOfParents: [
        //   {
        //     required: true,
        //     message: "请输入家长姓名",
        //     trigger: "blur",
        //   },
        // ],
        // phoneOfParent: [
        //   {
        //     required: true,
        //     message: "请输入家长手机号",
        //     trigger: "blur",
        //   },
        // ],
      },
      editFormRules: {},
    };
  },

  computed: {
    calTableData: function () {
      return this.tableData.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
    },
  },
  created() {
    this.tablePrint();
    this.printClassOption();
    this.printYearOption();
    this.printTeacherOption();
  },
  mounted() {},
  methods: {
    // excel上传
    handleChange(file) {
      this.fileTemp = file.raw;
      let fileName = file.raw.name;
      let fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
      // 判断上传文件格式
      if (this.fileTemp) {
        if (fileType == "xlsx" || fileType == "xls") {
          this.importf(this.fileTemp);
        } else {
          this.$message({
            type: "warning",
            message: "附件格式错误，请删除后重新上传！",
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！",
        });
      }
    },
    handleRemove() {
      this.fileTemp = null;
    },
    //导入的方法
    importf(obj) {
      this.importdialogVisible = true;
      let _this = this;
      // 通过DOM取文件数据
      this.file = obj;
      var rABS = false; //是否将文件读取为二进制字符串
      var f = this.file;
      var reader = new FileReader();
      //if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var rABS = false; //是否将文件读取为二进制字符串
        // var pt = this;
        var wb; //读取完成的数据
        var outdata;
        var reader = new FileReader();
        reader.onload = function () {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          var XLSX = require("xlsx");
          if (rABS) {
            // wb = XLSX.read(btoa(fixdata(binary)), { //手动转化
            //     type: 'base64'
            // });
            wb = XLSX.read(Buffer.from(binary, "base64").toString("base64"));
          } else {
            wb = XLSX.read(binary, {
              type: "binary",
            });
          }
          // outdata就是你想要的东西 excel导入的数据
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          // excel 数据再处理
          let arr = [];
          outdata.map((v) => {
            let jsonString = JSON.stringify(v)
              .replace(/\//g, "")
              .replace(/\s/gi, "");
            // console.log(jsonString);
            v = JSON.parse(jsonString);
            let obj = {};
            //xxx代表列名
            obj.stuId = v["学号"];
            obj.stuName = v["姓名"];
            obj.schoolDate = v["年份"];
            obj.stuClass = v["班级"];
            obj.classTeacher = v["班主任"];
            obj.idCard = v["身份证号码"];
            obj.updatetime = v["更新时间"];
            arr.push(obj);
          });
          _this.accountList = [...arr];
        };
        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    importExcel() {
      let that = this;
      this.$axios({
        url: "http://zh.zhihao.kooboo.site/Student/import",
        method: "post",
        data: {
          // importStudent :JSON.stringify(that.accountList)
          importStudent: that.accountList,
        },
      }).then((response) => {
        console.log(response);
        // console.log(that.accountList.length);
      });
      that.importdialogVisible = false;
      that.tablePrint();
    },

    // 导出
    exportExcel() {
      /* generate workbook object from table */
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#Student_table")
      );
      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "baseData.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    // 选中框
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 表格翻页
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    // 表格渲染
    tablePrint() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/Student/list",
      })
        .then((response) => {
          console.log(response);
            response.data.forEach(element => {
              element.isAssistant=element.isAssistant+'';
              element.isCommittee=element.isCommittee+'';
            });
          that.tableData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 下拉框渲染
    selectPrint() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/listcClass",
      })
        .then((response) => {
          // console.log(response);
          that.tableData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 删除按钮：删除checkbox选中的数据
    deleteInfo() {
      // console.log(this.multipleSelection);
      // multipleSelection是一个数组

      this.$confirm("确认删除吗？", "提示", {})
        .then(() => {
          console.log(this.multipleSelection);
          var ids = this.multipleSelection.map((m) => m._id);
          console.log(ids);

          for (let i = 0; i < ids.length; i++) {
            for (let j = 0; j < this.tableData.length; j++) {
              if (this.tableData[j]._id == ids[i]) {
                this.tableData.splice(j, 1);
              }
            }
            console.log(ids[i]);
            this.$axios({
              method: "POST",
              url: "http://zh.zhihao.kooboo.site/Student/del",
              data: {
                _id: ids[i],
              },
            })
              .then(() => {})
              .catch((error) => {
                console.log(error);
              });
          }

          this.$message({
            message: "提交成功",
            type: "success",
          });
        })
        .catch((e) => {
          console.log(e);
        });
      // this.tablePrint();
    },
    // 搜索按钮
    searchInFo() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/Student/list",
        data: {
          schoolDate: that.valueOfYear,
          stuClass: that.valueOfClass,
          stuId: that.inputID,
          stuName: that.inputName,
        },
      })
        .then((response) => {
          that.tableData = response.data;
          console.log(response);
          // this.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 新增按钮：添加按钮->dialog->保存按钮保存新数据
    newInfo() {
      this.addForm = {};
    },
    putInfo() {
      let that = this;
      var Time = new Date();
      var updatetime = Time.toLocaleString();
      // console.log(updatetime);
      that.tableData.push(that.addForm);

      console.log("that.addForm", that.addForm);
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/Student/new",
        data: {
        
          stuId: that.addForm.stuId,
          stuName: that.addForm.stuName,
          schoolDate: that.addForm.schoolDate,
          stuClass: that.addForm.stuClass,
          classTeacher: that.addForm.classTeacher,
          idCard: that.addForm.idCard,
          grade: that.addForm.grade,
          sex: that.addForm.sex,
          birthDate: that.addForm.birthDate,
          QQNumber: that.addForm.QQNumber,
          homeLocating: that.addForm.homeLocating,
          phoneOfStudent: that.addForm.phoneOfStudent,
          updateTime: updatetime,
          isCommittee: that.addForm.isCommittee,
          originPlace: that.addForm.originPlace,
          isAssistant: that.addForm.isAssistant,
          adScore: that.addForm.adScore,
          faith: that.addForm.faith,
          wechatOfStudent: that.addForm.wechatOfStudent,
          emailOfStudent: that.addForm.emailOfStudent,
          myScourse: that.addForm.myScourse,
          studyBelief: that.addForm.studyBelief,
          studyHarvest: that.addForm.studyHarvest,
          studyTarget: that.addForm.studyTarget,
          testScorce: that.addForm.testScorce,
          studyInput: that.addForm.studyInput,
          employability: that.addForm.employability,
          mentalHealth: that.addForm.mentalHealth,
          recreation: that.addForm.recreation,
          socialPractice: that.addForm.socialPractice,
          innovation: that.addForm.innovation,
          attendance: that.addForm.attendance,
          homeWork: that.addForm.homeWork,
          nameOfParents: that.addForm.nameOfParents,
          phoneOfParent: that.addForm.phoneOfParent,
          emailOfParent: that.addForm.emailOfParent,
          eduBackgroundOfParent: that.addForm.eduBackgroundOfParent,
          occupationOfParent: that.addForm.occupationOfParent,
          wechatOfParent: that.addForm.wechatOfParent,
          faithOfParent: that.addForm.faithOfParent,
          ideaOfParent: that.addForm.ideaOfParent,
          eduModeOfParent: that.addForm.eduModeOfParent,
          societyOfParent: that.addForm.societyOfParent,
          lifeStyleOfParent: that.addForm.lifeStyleOfParent,
        },
      })
        .then(() => {
          that.tablePrint();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 编辑按钮 打开表单 信息传入表单
    editInFo(row, view) {
      // 这里row是dataTable数组
      let that = this;
      console.log(row);
      that.editForm = {};
      that.view = false;
      that.editForm = row;
      that.view = view;
    },
    // 储存修改信息
    storeInfo() {
      let that = this;
      var nowTime = new Date();
      var updatetime = nowTime.toLocaleString();
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/Student/update",
        data: {
          stuId: that.editForm.stuId,
          stuName: that.editForm.stuName,
          schoolDate: that.editForm.schoolDate,
          stuClass: that.editForm.stuClass,
          classTeacher: that.editForm.classTeacher,
          idCard: that.editForm.idCard,
          grade: that.editForm.grade,
          sex: that.editForm.sex,
          birthDate: that.editForm.birthDate,
          QQNumber: that.editForm.QQNumber,
          homeLocating: that.editForm.homeLocating,
          phoneOfStudent: that.editForm.phoneOfStudent,
          updateTime: updatetime,
          isCommittee: that.editForm.isCommittee,
          originPlace: that.editForm.originPlace,
          isAssistant: that.editForm.isAssistant,
          adScore: that.editForm.adScore,
          faith: that.editForm.faith,
          wechatOfStudent: that.editForm.wechatOfStudent,
          emailOfStudent: that.editForm.emailOfStudent,
          myScourse: that.editForm.myScourse,
          studyBelief: that.editForm.studyBelief,
          studyHarvest: that.editForm.studyHarvest,
          studyTarget: that.editForm.studyTarget,
          testScorce: that.editForm.testScorce,
          studyInput: that.editForm.studyInput,
          employability: that.editForm.employability,
          mentalHealth: that.editForm.mentalHealth,
          recreation: that.editForm.recreation,
          socialPractice: that.editForm.socialPractice,
          innovation: that.editForm.innovation,
          attendance: that.editForm.attendance,
          homeWork: that.editForm.homeWork,
          nameOfParents: that.editForm.nameOfParents,
          phoneOfParent: that.editForm.phoneOfParent,
          emailOfParent: that.editForm.emailOfParent,
          eduBackgroundOfParent: that.editForm.eduBackgroundOfParent,
          occupationOfParent: that.editForm.occupationOfParent,
          wechatOfParent: that.editForm.wechatOfParent,
          faithOfParent: that.editForm.faithOfParent,
          ideaOfParent: that.editForm.ideaOfParent,
          eduModeOfParent: that.editForm.eduModeOfParent,
          societyOfParent: that.editForm.societyOfParent,
          lifeStyleOfParent: that.editForm.lifeStyleOfParent,
        },
      })
        .then(() => {
          that.tablePrint();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    printClassOption() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/listcClass",
      })
        .then((response) => {
          console.log(response);
          let i = 0;
          while (response.data[i]) {
            that.optionsOfClass.push(JSON.parse(response.data[i++]));
          }
          console.log(that.optionsOfClass);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    printYearOption() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/listSchoolDate",
      })
        .then((response) => {
          let i = 0;
          while (response.data[i]) {
            that.optionsOfYear.push(JSON.parse(response.data[i++]));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    printTeacherOption() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/listclassTeacher",
      })
        .then((response) => {
          let i = 0;
          while (response.data[i]) {
            that.optionsOfClassTeacher.push(JSON.parse(response.data[i++]));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.BasicData-deleteButton {
  margin-right: 2%;
}

.BasicData-selectYear {
  margin-right: 8px;
  width: 130px;
}
.select {
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
}

.BasicData-selectClass {
  width: 150px;
  margin-right: 1%;
}

.BasicData-inputID {
  max-width: 10%;
  margin-right: 1%;
}

.BasicData-inputName {
  max-width: 10%;
  margin-right: 1%;
}
.functionBar {
  display: flex;
  justify-content: space-between;
}
item {
  display: flex;
  flex-wrap: wrap;
}
.btns {
  display: flex;
  justify-content: flex-start;
}
</style>
