<template>
  <div>
    <img src="../../assets/LoginPage/welcome.jpg" style="width:95%;height: 95%; margin-top:15px;">

  </div>
  

</template>

<script>
export default {
    name: 'Welcome',
    data() {
      return {};
    }
}
</script>

<style>

</style>