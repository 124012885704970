<template>
  <div>
    <div class="headerText">
      <span style="font-weight: bold; color: #777777">课程数据</span>
    </div>

    <div class="functionBar">
     <div class="btns">
      
          <el-button type="primary" @click="handleAdd()">新增</el-button>

     </div>
     <div class="select">
         <el-input
         
          v-model="courseName"
          placeholder="课程名称"
          style="width: 150px"
              clearable
        ></el-input>
   
      <el-button type="primary" @click="searchInFo() " style="margin-left:8px">搜索</el-button>
     </div>
    </div>

    <div class="dataTable">
      <el-table :data="calTableData" border width="100%">
   <el-table-column prop="courseId" label="课程编号" align="center">
        </el-table-column>
        <el-table-column prop="courseName" label="课程名称" align="center">
        </el-table-column>
        <el-table-column prop="teacherName" label="任课老师" align="center">
        </el-table-column>
         <el-table-column prop="courseSite" label="上课地点" align="center">
        </el-table-column>
        <el-table-column prop="courseArrangement" label="上课时间" align="center" >
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center">
        </el-table-column>
        <el-table-column prop="operation" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
             <el-button type="text" @click="deleteCourse(scope.row)" style="color:red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="tableTag">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
       
    <!-- 新增 -->
    <el-dialog
      title="新增签到"
      :visible.sync="newDialogVisible"
      :before-close="handleClose()"
      width="50%"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="课程名称" prop="courseName" >
          <el-input
            placeholder="课程名称"
            v-model="addForm.courseName"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
               <el-form-item label="课程编号" prop="courseId" >
          <el-input
            placeholder="课程编号"
            v-model="addForm.courseId"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="上课时间" >
          <el-col :span="11">
            <el-form-item prop="courseArrangement">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="addForm.courseArrangement"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
          <el-form-item label="上课地点" prop="courseSite" >
          <el-input
            placeholder="上课地点"
            v-model="addForm.courseSite"
            style="width: 20%"
          >
          </el-input>
        </el-form-item>
         
        <el-form-item label="任课老师" prop="teacherName">
          <el-input
            placeholder="任课老师"
            v-model="addForm.teacherName"
            style="width: 20%"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            newDialogVisible = false;
            handlePut();
          "
          >保 存</el-button
        >
        <el-button @click="newDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑签到"
      :visible.sync="editDialogVisible"
      :before-close="handleClose()"
      width="50%"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
      
         <el-form-item label="课程名称" prop="courseName">
     
       
          <el-input
            placeholder="课程名称"
            v-model="editForm.courseName"
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="上课时间" >
          <el-col :span="11">
            <el-form-item prop="courseArrangement">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="editForm.courseArrangement"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
         
          <el-form-item label="上课地点" prop="courseSite" >
          <el-input
            placeholder="上课地点"
            v-model="editForm.courseSite"
            style="width: 20%"
          >
          </el-input>
        </el-form-item>
       
      
        <el-form-item label="任课老师" prop="teacherName">
          <el-input
            placeholder="任课老师"
            v-model="editForm.teacherName"
            style="width: 20%"
           
          >
          </el-input>
        </el-form-item>
     
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            editDialogVisible = false;
            handleStore();
          "
          >保 存</el-button
        >
        <el-button @click="editDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>


 
  </div>
</template>

<script>
export default {
  name: "Course",
  data() {
    return {
      pushNumberForm: {
        noticeContent: "",
      },
      studentNum:0,
      pushProbabilityForm: {
        noticeContent: "",
      },
      checkedClassTeacher: true,
      checkedTeacherName: false,
      activeName: "first",
      isPushProbability: false,
      isPushNumber: false,
      pushDialogVisible: false,
      courdeSite:'',
      classHours:0,
      credit:0,
      courseName:'',
      SignInTableData: [],
      newDialogVisible: false,
      editDialogVisible: false,
      addForm: {},
      addCourseName:{},
      editForm: {},
      editFormRules: {},
      optionsOfYear: [
        {
          valueOfYear: "2021",
          label: "2021",
        },
        {
          valueOfYear: "2020",
          label: "2020",
        },
      ],
      valueOfYear: "",
      addFormRules: {
      
     
       
          courseName: [
          {
            required: true,
            message: "课程名称",
            trigger: "blur",
          },
        ],
         courseId: [
          {
            required: true,
            message: "课程编号",
            trigger: "blur",
          },
        ],
      },
      optionsOfCourse: [
      
      ],
      valueOfCourse: "",
      currentPage: 1,
      pagesize: 5,
      tableData: [],
      courseId:'',
      inputNumber: "",
      earlyLeaveNum:0,
      inputProbability: "",
      inputOfTeacher: "",
      optionsOfcurriculum: [
        {
          valueOfCourseName: "高数",
          label: "高数",
        },
        {
          valueOfCourseName: "离散",
          label: "离散",
        },
      ],
      valueOfCourseName: "",
    };
  },
  created() {
    this.tablePrint();
    this.printClassOption();
    this.getcourseName();
  },
  mounted() {},
  methods: {
 
 
getcourseName(){
   let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/courseName",
      })
        .then((response) => {
          console.log(response);
          let i = 0;
          while (response.data[i]) {
            that.optionsOfcurriculum.push(JSON.parse(response.data[i++]));
          }
          console.log(that.optionsOfcurriculum);
        })
        .catch((error) => {
          console.log(error);
        });
},
    
    
    handleClose() {
      // this.$confirm('确认关闭？')
      //     .then(() => {
      //       this.newDialogVisible = false;
      //       this.editDialogVisible = false;
      //     })
      //     .catch((e)=> {
      //       console.log(e);
      //     });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //    async handleAdd() {
    //   this.$router.push({ path: 'class/edit' })
    // },
    handleAdd() {
      this.newDialogVisible = true;
      this.addForm = {};
    },
    handlePut() {
      let that = this;
      var Time = new Date();
      var updatetime = Time.toLocaleString();
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/addCourse",
        data: {
          courseArrangement: that.addForm.courseArrangement,
          courseName: that.addForm.courseName,
          courseSite:that.addForm.courseSite,
         // courseArrangement:that.addForm.courseArrangement,
          teacherName: that.addForm.teacherName,
          courseId: that.addForm.courseId,
          updateTime: updatetime,
        },
      })
        .then((response) => {
          console.log(response);
          that.tableData=response.data;
          that.tablePrint();
        })
        .catch((error) => {
          console.log(error);
        });
    },
   searchInFo() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/courseList",
        data: {
          courseName:that.courseName
        },
      })
        .then((response) => {
          that.tableData = response.data;
          console.log(response);
          // this.reload();
            this.ablePrint();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
       //删除操作
    deleteCourse(row) {
      this.$confirm("确认删除记录吗？","提示",{
            type:"warning"
      })
      .then(() =>{
       this.$axios({
        method: "GET",
        url: "http://zh.zhihao.kooboo.site/delCourse",
        params: {
         courseId:row.courseId
        },
      }).then(()=>{
           this.tablePrint();
      })
      })
      .catch((e) =>{
          console.log(e)
      })
    },
    // 编辑按钮
    handleEdit(row) {
      this.editDialogVisible = true;
      this.editForm = row;
      console.log(this.editForm);
    },
    // 保存编辑 时间有问题
    handleStore() {
      let that = this;
      var Time = new Date();
      var updatetime = Time.toLocaleString();
    
    
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/updateCourse",
        data: {
          courseName: that.editForm.courseName,
          teacherName: that.editForm.teacherName,
          courseSite:that.editForm.courseSite,
           courseArrangement:that.editForm.courseArrangement,
       //  courseArrangement:that.editForm.courseArrangement,
          // attendanceFrom: that.addForm.attendanceFrom,
          updateTime: updatetime,
          courseId:that.editForm.courseId
        },
      })
        .then((response) => {
          console.log(response);
          // that.tableData = response.data;
          that.tablePrint();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 表格翻页
    handleSizeChange: function(size) {
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
    },
    // 表格渲染
    // that 是vue中的this这里需要保存一下 我也不知道为啥
    tablePrint() {
      let that = this;
      this.$axios({
        method: "POST",
        url: "http://zh.zhihao.kooboo.site/courseList",
      })
        .then((response) => {
          console.log(response);
          that.tableData = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    calTableData: function() {
      return this.tableData.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
    },
  },
};
</script>

<style>
.SignIn-functionBar {
  box-sizing: border-box;
  padding: 15px 0 0 0;
  display: flex;
}
.btns{
 display: flex;
  justify-content:flex-start ;
}
.select{
  display: flex;
  justify-content: flex-end ;
 margin-right: 8px;
}
.SignIn-selectYear {
  width: 150px;
margin-right: 8px;
}

.SignIn-selectClass {
  width: 150px;
 margin-right: 8px;
}

.SignIn-dataTable {
  box-sizing: border-box;
  padding: 15px 0 0 0;
}

.SignIn-tableTag {
  box-sizing: border-box;
  padding: 15px 0 0 0;
}
</style>
