<template>
  <div>
    <div class="headerText">
      <span style="font-weight: bold; color: #777777">老师管理</span>
    </div>
    <div class="functionBar" >
      <el-button type="primary" @click="handleadd" style="margin-left:8px">新增</el-button>
     <div class="select">
        <el-col v-model="filters">

      <el-input
        class="TeccherManage-inputName"
        v-model="filters.name"
        placeholder="姓名"
        clearable 
        @clear="searchTeacher()"
      ></el-input>

      <el-button type="primary" @click="searchTeacher">搜索</el-button>

      </el-col> 
     </div>
    </div>

    <div class="dataTable">
      <!-- 未完成 -->
      <el-table
        :data="calTableData"
        border
        style="width: 100%"
      >
     <el-table-column prop="usrName" label="用户名" align="center">
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="number" label="工号" align="center">
        </el-table-column>
        <el-table-column prop="adress" label="家庭住址" align="center" width="120px">
        </el-table-column>
          <el-table-column prop="academicTitle" label="职称" align="center">
        </el-table-column>
        <el-table-column prop="eduBackground" label="学历" align="center">
        </el-table-column>
        <el-table-column prop="course" label="开设课程" align="center">
        </el-table-column>
        <el-table-column prop="currentCourse" label="当前课表" align="center">
        </el-table-column>
            <el-table-column prop="phoneOfTeacher" label="手机号" align="center" width="180px">
        </el-table-column>
        <el-table-column prop="eamil" label="邮箱" align="center">
        </el-table-column>
        <el-table-column prop="qqNum" label="qq号" align="center">
        </el-table-column>
        <el-table-column prop="wechat" label="微信号" align="center">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center" width="180px">
        <template slot-scope="scope" >
                <div>{{handleDate(scope.row.updateTime)}}</div>
                <div>{{handleTime(scope.row.updateTime)}}</div>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdate(scope.$index,scope.row)">编辑</el-button>
         
            <el-button type="text" style="color:red" @click="deleteTeacher(scope.$index,scope.row)">删除</el-button>
           
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="tableTag">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[7, 14, 21, 28]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="userlist.length"
      > 
      </el-pagination>
    </div>

    <!-- 新增编辑页面 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :close-on-click-modal="false" ref="editForm">
      <el-form :model="editForm" label-width="150px" :rules="editFormRules" ref="editForm">
        <el-form-item label="用户名：" prop="usrName">
          <el-input id="usrName"  v-model="editForm.usrName"></el-input>
        </el-form-item>

        <el-form-item label="姓名:" prop="name">
          <el-input id="name" v-model="editForm.name"></el-input>
        </el-form-item>

        <el-form-item label="工号" prop="number">
          <el-input id="userNumber" v-model="editForm.number"></el-input>
        </el-form-item>
          <el-form-item label="家庭住址" prop="adress">
          <el-input id="userName"  v-model="editForm.adress"></el-input>
        </el-form-item>

        <el-form-item label="职称:" prop="academicTitle">
          <el-input id="userRealName" v-model="editForm.academicTitle"></el-input>
        </el-form-item>

        <el-form-item label="学历" prop="eduBackground">
          <el-input id="userNumber" v-model="editForm.eduBackground"></el-input>
        </el-form-item>

  <el-form-item label="开设课程" prop="course">
          <el-input id="userName"  v-model="editForm.course"></el-input>
        </el-form-item>

        <el-form-item label="当前课表:" prop="currentCourse">
          <el-input id="userRealName" v-model="editForm.currentCourse"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phoneOfTeacher">
          <el-input id="userNumber" v-model="editForm.phoneOfTeacher"></el-input>
        </el-form-item>
   <el-form-item label="qq号:" prop="qqNum">
          <el-input id="userRealName" v-model="editForm.qqNum"></el-input>
        </el-form-item>

        <el-form-item label="微信号" prop="wechat">
          <el-input id="userNumber" v-model="editForm.wechat"></el-input>
        </el-form-item>


      </el-form>
      <div  style="text-align: right">
        <el-button v-if="dialogStatus=='create'" type="primary" @click="addUser">保存</el-button>
        <el-button v-else type="primary" @click="updateTeacher">保存</el-button>
        <el-button @click="dialogFormVisible=false" type="danger">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getAllTeacher,
        deleteTeacher,
        updateTeacher,
        addTeacherInfo,
        searchTeacher,
        } from "@/network/teacher_management.js";


// const defaultListQuery = {
//     name: null,
//     pageNum: 1,
//     pageSize: 5,
// }
export default {
  name: "TeccherManage",
  data() {
    return {
      filters: {
        usrName: '',
        name: '',
        number: '',
        role: '',
        isUse: ''
      },
    //   listQuery: Object.assign({}, defaultListQuery),
    //   listLoading: true,
      currentPage: 1,
      pagesize: 7,
      dialogStatus: '',
      textMap: {
        create:"新增",
        update:"修改"
      },
      dialogFormVisible: false,
      //新增修改页面
      editForm: {
        usrName: "",
        name: "",
        number: "",
        role:"teacher",
      },
      editFormRules: {
        usrName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名" , trigger: "blur" }],
        number: [{ required: true, message: "请输入编号", trigger: "blur" }],
        role: [{ required: true, message: "请选择角色", trigger: "blur" }],
      },
      userlist: [],
      optionsOfCharacter: [{
          value: '教导主任',
          label: '教导主任'
        }, {
          value: '老师',
          label: '老师'
        }, {
          value: '超级管理员',
          label: '超级管理员'
        },],
        valueOfCharacter: '',
        optionsOfOpen: [{
          value: '1',
          label: '是'
        }, {
          value: '0',
          label: '否'
        }, ],
        valueOfOpen: '',
      
    };
  },
  computed: {
    calTableData: function() {
      return this.userlist.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
    },
  },
  methods: {
    //查询所有的列表信息(在create里调用)
    getUser(){
    //   let para = {pageNum: this.listQuery.pageNum,pageSize:this.listQuery.pageSize}
         getAllTeacher().then(res =>{
            console.log(res);
            this.userlist= [];
            this.userlist = res;
            // console.log(this.userlist);
            
          })
    },
   handleSizeChange: function(size) {
        this.pagesize = size;
        },
    handleCurrentChange: function(currentPage) {
        this.currentPage = currentPage;
    },
    handleDate(time){
		var date = new Date(time);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
		var Y = date.getFullYear() + '-';
		var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
		var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate());
		return Y+M+D;
	},
	handleTime(time){
		var date = new Date(time);
		var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
		var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
		var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
		return h+m+s;
	},
    //搜索模糊查询
    searchTeacher() {
            searchTeacher({name:this.filters.name}).then(res =>{
              console.log(res);
              // this.userlist = []
               this.userlist = res;
            //   console.log(this.userlist);
        })
    },

    //弹出新增页面
    handleadd() {
      this.dialogStatus = "create",
      this.dialogFormVisible = true,
      this.editForm = {
        usrName: "",
        name: "",
        number: "",
        phoneOfTeacher:'',
        isClassTeacher:'',
        course:'',
        eamil:'',
        adress:'',
        academicTitle:'',
        eduBackground:'',
        currentCourse:'',
        qqNum:'',
        wechat:''
      }
    },

    //提交新增
     addUser() {
     this.$refs.editForm.validate(valid =>{
            if(valid){
              this.$confirm("确认提交吗？","提示",{})
              .then(() =>{
                let para = this.editForm;
                console.log(para);
                addTeacherInfo(para).then(() =>{
                  this.$message({
                    message:"提交成功",
                    type:"success"
                  });
                  this.$refs["editForm"].resetFields();
                  this.dialogFormVisible = false;
                  this.getUser();
                });
              })
              .catch(e =>{
                console.log(e);
              });
            }
          });
    },

    //弹出编辑页面
    handleUpdate(index,row) {
      console.log(index);
      this.dialogStatus = "update",
      this.dialogFormVisible = true,
    //   this.editForm = Object.assign({},row);
    this.editForm = row;
    },

    //提交修改
    updateTeacher() {
       this.$refs.editForm.validate(valid =>{
            if(valid){
              this.$confirm("确认提交吗？","提示",{})
              .then(() =>{
                // let para = Object.assign({},this.editForm);
                let para = this.editForm;
                console.log(para);
                updateTeacher(para).then(() =>{
                  this.$message({
                    message:"提交成功",
                    type:"success"
                  });
                  this.$refs["editForm"].resetFields();
                  this.dialogFormVisible = false;
                  this.getUser();
                });
              })
              .catch(e =>{
                console.log(e);

              });
            }
          });
    },

    //删除操作
    deleteTeacher(index,row) {
      this.$confirm("确认删除记录吗？","提示",{
            type:"warning"
      })
      .then(() =>{
        let para = {id:row._id};
        deleteTeacher(para)
        .then(res => {
          console.log(res);
           this.$message({
                message:"删除成功",
                type:"success"
              });
              this.getUser();
        });
      })
      .catch(() =>{
      })
    },
 
  },

  mounted() {
    this.getUser();
  }
};
</script>

<style>
.TeccherManage-inputUserName {
    width: 130px;margin-right: 8px;
}

.TeccherManage-inputName {
    width: 130px;margin-right: 8px;
}

.TeccherManage-inputNum {
    width: 130px;margin-right: 8px;
}

.TeccherManage-selectCharacter {
    width: 150px;margin-right: 8px;

}
.time{
    width: 150px;
}
.TeccherManage-selectOpen {
    width: 150px;margin-right: 8px;

}
.select{
   display: flex;
  justify-content: flex-end;
   margin-right: 8px;
}
</style>
